'use strict';

$(
	function() {
		try {
			var topH = $('._ > .top').height();
			app.fixTop = topH || 0;
		} catch (e) {}
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.attachSvgEvents();
		app.makeSocialButtons();
		app.initACBL();
		app.setBackUrls();
		app.addCoverSpan();
		app.saveProductListInfo();
		app.topMenu.init();
		if (!app.isMobile()) {
			$(window).scroll(
				function () {
					app.floatingHeader();
				}
			);
			app.floatingHeader();
		}
		app.assignProductOver();
		app.initializeScrollButton();
		app.hamburger.init({
			look: 1
		});
		app.color.init();
		app.extraMenu && app.extraMenu.init({
			side: 'right',
			selector: '.extramenu-hamb',
			saveState: false
		});
		app.template.set();
	}
);

var app = {
	_VERSION: 2,
	_vars: {
		putAttributesToProdItemsDirectly: true,
		_customFonts: {
			MaterialIcons: true
		},
		v: 1,
		wishListIconPlaceSelector: '._ .main-image .image-inner',
		noFancyForQuickView: true,
		noWishListWordingKey: 'cfg__noFavorites',
		wishListAddMessage: {
			en: 'Product was added to your <a href="javascript:app.wishList.callWishList()">Favorites</a>.',
			fr: 'Le produit a été ajouté à <a href="javascript:app.wishList.callWishList()">votre liste des favoris</a>.'
		},
		_guid: '13492534-1b48-4bc4-b22e-6eba450b31b4'
	},

	responsiveMode: true,
	indexLinkPos: 0,
	messages: {},
	productAvailableQuantityUrl: null,
	optionsWereInitialized: false,
	doAfterProdListAttributesDone: function ($list, savedData) {
		$list.each(
			function () {
				var $this = $(this);
				var $item = $this.closest('.item');

				// remove the default color icon
				var $colorAttr = $item.find('.pl-colors');
				if ($colorAttr.length > 0) {
					$colorAttr.find(':not([data-origin])').remove();
				}

				// add brand
				if ($item.find('.udu__brand').length > 0) {
					return true;
				}
				var href = $this.attr('href');
				if (!href) {
					href = $this.data('href');
				}
				var prodId = app.prodListAttrs.getIdByHref(href);

				if (savedData[prodId] && savedData[prodId]['_BRAND']) {
					var brandAttr = savedData[prodId]['_BRAND'];
					var brandName = '';
					if (typeof brandAttr === 'string') {
						brandName = brandAttr;
					}
					$item.find('.name').before('<div class="udu__brand">' + brandName + '</div>');
				} else {
					$item.find('.name').before('<div class="udu__brand"></div>');
				}
			}
		);
	},
	template: {
		set: function () {
			this.collapse();
			this.extraMenuTopDistance();
			this.modifyWishList();
		},
		initSlider: function (params) {
			var {
				sliderHeight,
				sliderAutoplayTimeout,
				leftMenu
			} = params || {};

			var num = params.imgSize === 120 ? 5 : params.imgSize === 250 ? 4 : 3;
			var generalResponsive = {
				0: {
					items: 1
				},
				425: {
					items: 1
				},
				544: {
					items: 2
				},
				761: {
					items: 3
				},
				992: {
					items: leftMenu !== 'Y' ? num - 1 : num - 2
				},
				1200: {
					items: leftMenu !== 'Y' ? num : num - 1
				}
			};

			app.owlSlider.makeBanner({
				speed: sliderAutoplayTimeout,
				animateIn: 'fadeIn',
				animateOut: 'fadeOut',
				setHeight: sliderHeight
			});
			app.owlSlider.makeProductListSlider({
				oneRow: true,
				margin: 0,
				loop: false,
				rewind: true,
				responsive: generalResponsive
			});
		},
		collapse: function () {
			$(document).on('click', '[data-udu-toggle="collapse"]', function () {
				var target = $(this).siblings('.is-collapse');
				if ($(this).attr('aria-expanded') === 'false') {
					$(target).slideDown().addClass('in');
					$(this).removeClass('is-collapsed').attr('aria-expanded', 'true');
				} else {
					$(target).slideUp().removeClass('in');
					$(this).addClass('is-collapsed').attr('aria-expanded', 'false');
				}
			});
		},
		modifyProductPageHtml: function (params) {
			var { brandName } = params || {};
			var modifyCss = 'productPageModified';
			var $productPage = $('.product-view');

			$productPage.each(function() {
				var $page = $(this);

				if (!$page.length || $page.hasClass(modifyCss)) {
					return;
				}

				// brand name
				if (brandName) {
					$page.find('div.name span.name').after('<div class="udu__brand">' + brandName + '</div>');
				}

				// add to cart button
				var $imMode = $('.udu__mult-inv-disp-mode-IM', $page);
				if ($imMode.length) {
					$('.udu__mult-im tbody', $imMode).append('<tr><td colspan="100%" class="submit-btn"></td></tr>');
					$('.udu__mult-im .submit-btn', $imMode).append($('.span-submit', $page));

					// product options
					if ($('.cart-form ul.product-options > li', $page).length > 0) {
						$('.udu__summary-delimiter', $imMode).before('<tr><td colspan="100%" class="product-options-im"></td></tr>');
						var $genOptions = $('.cart-form .prod-general-opt', $page);
						if ($genOptions.length > 0) {
							var $contentOptions = $genOptions;
						} else {
							$contentOptions = $('.cart-form ul.product-options', $page);
						}
						$('.product-options-im', $imMode).append($contentOptions);
					}
				}

				// product attributes
				var $prodAttr = $('.prod-attributes', $page);
				if ($prodAttr.length && !$prodAttr.find('> ul > li').length) {
					$prodAttr.remove();
				}

				// product options
				var $optionList = $('.product-images-block .product-images.options', $page);
				if ($optionList.length > 0) {
					var $mainItem = $optionList.find('.udu__opt-img-pos-0');

					if ($mainItem.length > 0) {
						$mainItem.on('click', function() {
							var $this = $(this);
							var optId = $this.attr('data-element-id');

							var $options = $optionList.find('[data-element-id=' + optId + ']:not(.udu__opt-img-pos-0)');
							var $prodImg = $('[class*="product-images-cnt-"]', $page);

							if ($prodImg.length > 0) {
								$prodImg.find('.ul-prod-img > li').remove();
							} else {
								var prodImgHtml = '<div class="product-images product-images-cnt-"><ul class="ul-prod-img"></ul></div>';
								$('.product-images-block', $page).prepend(prodImgHtml);
							}

							if ($options.length > 0) {
								if ($prodImg.hasClass('is-hidden')) {
									$prodImg.removeClass('is-hidden');
								}
								$options.each(function() {
									$('[class*="product-images-cnt-"] .ul-prod-img', $page).append($(this).clone());
								});
							} else {
								if (!$prodImg.hasClass('is-hidden')) {
									$prodImg.addClass('is-hidden');
								}
							}
						})
					}
				}

				$page.addClass(modifyCss);
			});
		},
		modifyCategoryPageHtml: function () {
			var $page = $('.bg-category');
			if (!$page.length) {
				return;
			}

			var $breadCrumb = $('.bread-crumb');
			if ($breadCrumb.length) {
				$('.product-list-top-part').append($breadCrumb);
			}
		},
		modifyWishList: function() {
			app.events.addListener(
				'udu_ON-WISH-LIST-POPUP-OPEN',
				function(data) {
					if (data) {
						data.stopGo = true;
					}

					app.getURL('user/log-in', function(res) {
						if (res) {
							var url = app.url.formURL(res, { openAccord: 'favorites' });
							app.url.goTo(url);
						}
					})
				}
			);
		},
		extraMenuTopDistance: function () {
			var extraMenuTop = $('._ .wrapper-top-line').height();
			if (!extraMenuTop) {
				extraMenuTop = 70;
			}
			app.utils.css.cssVar('--extraMenuTop', extraMenuTop + 'px');
		}
	}
};
